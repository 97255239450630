//@author: devin
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { LooseAutocomplete } from "../../pojo/Util";

@TypeManagerDecorator("s25-popover")
@Component({
    selector: "s25-popover",
    template: `
        <div
            *ngIf="init"
            class="ngInlineBlock"
            (mouseleave)="close($event)"
            (focusout)="close($event)"
            (mouseenter)="cancelCloseFn()"
            (click)="cancelCloseFn()"
        >
            <div *ngIf="disabled">
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </div>
            <div
                *ngIf="!disabled"
                #po="ngbPopover"
                [popoverClass]="this.modelBean.popoverClass"
                [ngbPopover]="modelBean.popoverTemplate"
                [autoClose]="modelBean.autoClose"
                (shown)="initPopOverFn()"
                (hidden)="hidePopOverFn()"
                (mouseenter)="open($event)"
                (focusin)="open($event)"
                (keydown.enter)="open($event)"
                [container]="onBody ? 'body' : null"
                [placement]="placement"
                tabindex="0"
            >
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </div>
        </div>
        <ng-template #content><ng-content></ng-content></ng-template>
    `,
})
export class PopoverComponent implements OnInit, OnDestroy {
    @Input() modelBean: any;
    @Input() popoverClass: string;
    @Input() onBody?: boolean = true; //default to popover on body https://ng-bootstrap.github.io/#/guides/positioning
    @Input() openTrigger?: "mouseenter" | "click" = "mouseenter";
    @Input() closeTrigger?: "mouseleave" | "click" = "mouseleave";
    @Input() placement?: NgbPlacementArray = "auto"; //acceptable values
    @Input() disabled = false; // Set to true to prevent pop-overs

    @Output() shown = new EventEmitter<PopoverComponent["modelBean"]>();
    @Output() hidden = new EventEmitter<PopoverComponent["modelBean"]>();

    @ViewChild(NgbPopover, { static: false }) popoverChild: NgbPopover;

    public static CLOSE_DELAY: number = 600;
    public static OPEN_DELAY: number = 500;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
        private renderer: Renderer2,
    ) {}

    init = false;
    openTimeout: any = undefined;
    closeTimeout: any = undefined;
    eventListeners: any = [];

    //on mouseenter, open the popover after a slight delay, which is canceled if mouseleave happens during the delay
    open($event: any) {
        const openTriggerMatch =
            (this.openTrigger === "click" && ($event.type === "click" || $event.key === "Enter")) ||
            $event.type === this.openTrigger;

        if (this.disabled) return;
        if (
            (!$event || openTriggerMatch || ($event.type === "focusin" && this.modelBean.isTooltip)) &&
            this.popoverChild &&
            !this.popoverChild.isOpen()
        ) {
            if (this.openTimeout) {
                //if already opening, cancel previous open
                clearTimeout(this.openTimeout);
            }
            this.openTimeout = setTimeout(() => {
                //start opening
                this.openTimeout = undefined; //reset open timeout
                this.popoverChild.open();
            }, this.modelBean.openDelay || PopoverComponent.OPEN_DELAY);
        }
    }

    //on mouseleave , close after delay, which is canceled if popover entered
    close($event?: any) {
        if (!$event || $event.type === this.closeTrigger || ($event.type === "focusout" && this.modelBean.isTooltip)) {
            if (this.popoverChild && this.popoverChild.isOpen()) {
                //open() has set popover instance
                if (!this.closeTimeout) {
                    //if not already closing and still open
                    this.closeTimeout = setTimeout(() => {
                        //close after delay
                        this.closeTimeout = undefined;
                        this.forceClose();
                    }, this.modelBean.closeDelay || PopoverComponent.CLOSE_DELAY);
                }
            } else {
                //open() has not finished yet, so cancel open, a mouse move should not stop popover open
                if (this.openTimeout) {
                    clearTimeout(this.openTimeout);
                    this.openTimeout = undefined;
                }
            }
        }
    }

    forceOpen() {
        this.popoverChild?.open?.();
    }

    forceClose() {
        this.popoverChild?.close?.();
    }

    //cancel closing if user enters popover content during cancel delay
    cancelCloseFn() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
            this.closeTimeout = undefined;
        }
    }

    //get popover data on popover shown event
    initPopOverFn() {
        //extract uuid class and add handlers to popover content container
        let parts = this.modelBean.popoverClass.split(" ");
        let popoverUUID = parts[parts.length - 1];
        let popElem = popoverUUID && document.querySelector("." + popoverUUID);
        this.modelBean.popoverElement = popElem;

        //cancel close since we are in the popover content
        this.eventListeners.push(
            this.renderer.listen(popElem, "mouseenter", () => {
                this.cancelCloseFn();
            }),
        );

        this.eventListeners.push(
            this.renderer.listen(popElem, "click", () => {
                this.cancelCloseFn();
            }),
        );
        // jSith.on(
        //     popElem,
        //     "mousemove.popover mouseover.popover click.popover",
        //     () => {
        //         this.cancelCloseFn();
        //     },
        //     true
        // );

        //close if we are outside the popover content
        //note: if we leave content but enter the popup triggering element, close is canceled
        // this.closeTrigger === "mouseleave" &&
        //     jSith.on(
        //         popElem,
        //         "mouseleave.popover",
        //         (e: any) => {
        //             this.close(e);
        //         },
        //         true
        //     );
        this.closeTrigger === "mouseleave" &&
            this.eventListeners.push(
                this.renderer.listen(popElem, "mouseleave", (e) => {
                    this.close(e);
                }),
            );

        //close if we click on body; note: close is canceled if we are inside the content or triggering element
        // jSith.on(
        //     document.body,
        //     "click.popover",
        //     (e: any) => {
        //         //only close if we are OUTSIDE popup elem
        //         if (popElem !== e.target && !popElem.contains(e.target)) {
        //             this.close(e);
        //         }
        //     },
        //     true
        // );

        popElem.querySelector("input")?.focus?.();
        this.shown.emit(this.modelBean);

        let p = this.modelBean.onShow && this.modelBean.onShow(this.modelBean);
        p = (p && p.then && p) || jSith.when();
        return p.then(() => {
            this.modelBean.initPopOver = true;
            this.cd.detectChanges();
        });
    }

    //reset some of the popover state when closed
    hidePopOverFn() {
        this.closeTimeout = undefined;
        this.openTimeout = undefined;
        this.hidden.emit(this.modelBean);
        this.modelBean.onHide && this.modelBean.onHide(this.modelBean);
    }

    ngOnInit() {
        this.zone.run(() => {
            this.sanitizePlacement();
            //add uuid class to popover
            this.modelBean.popoverClass = this.modelBean.popoverClass || "";
            this.modelBean.popoverClass +=
                (this.modelBean.popoverClass ? " " : "") +
                ("s25Popover-" + Date.now() + "-" + Math.floor(Math.random() * 10000));

            this.modelBean.forceClosePopup = () => {
                return this.forceClose();
            };

            this.modelBean.openPopup = () => {
                return this.open(null);
            };

            this.modelBean.autoClose = S25Util.coalesce(this.modelBean.autoClose, "outside");

            this.init = true;
            this.cd.detectChanges();
        });
    }

    ngOnDestroy() {
        for (let listener of this.eventListeners) listener();
    }

    //ngbPopover now errors if you send invalid placements: https://ng-bootstrap.github.io/#/guides/positioning#api
    sanitizePlacement() {
        if (!Array.isArray(this.placement)) {
            this.placement = this.placement.split(" ") as NgbPlacement[];
        }

        this.placement = this.placement.filter((p) => validPlacements.has(p)) as NgbPlacementArray;
    }

    restoreFocus() {
        // reset focus to trigger element when popover is closed
        this.elementRef.nativeElement.querySelector("div[tabindex='0']")?.focus?.();
    }
}

const validPlacements = new Set<NgbPlacement>([
    "auto",
    "top",
    "top-start",
    "top-left",
    "top-end",
    "top-right",
    "bottom",
    "bottom-start",
    "bottom-left",
    "bottom-end",
    "bottom-right",
    "start",
    "left",
    "start-top",
    "left-top",
    "start-bottom",
    "left-bottom",
    "end",
    "right",
    "end-top",
    "right-top",
    "end-bottom",
    "right-bottom",
]);

export type NgbPlacement =
    | "auto"
    | "top"
    | "top-start"
    | "top-left"
    | "top-end"
    | "top-right"
    | "bottom"
    | "bottom-start"
    | "bottom-left"
    | "bottom-end"
    | "bottom-right"
    | "start"
    | "left"
    | "start-top"
    | "left-top"
    | "start-bottom"
    | "left-bottom"
    | "end"
    | "right"
    | "end-top"
    | "right-top"
    | "end-bottom"
    | "right-bottom";
export type NgbPlacementArray = LooseAutocomplete<NgbPlacement> | NgbPlacement[];
