//@author: devin
import { LangService } from "../../services/lang.service";
import { S25Util } from "../../util/s25-util";
import { DomResp, DomService } from "../../services/dom.service";
import { ServiceLoader } from "../../services/service.loader";
import { PopoverComponent } from "./popover.component";
import { NgZone } from "@angular/core";
import { PopoverDefaultComponent } from "./popover.default.component";

export class PopoverService {
    public static popups: any = [];
    public static isWorking = false;
    public static domService: DomService = undefined;
    public static zone: NgZone = undefined;

    public static createPopup(popoverContentComponent: any, modelBean: any, $event?: any, $target?: any) {
        PopoverService.domService = PopoverService.domService || ServiceLoader.get(DomService);
        PopoverService.zone = PopoverService.zone || ServiceLoader.get(NgZone);
        if (!PopoverService.isWorking) {
            PopoverService.isWorking = true;
            PopoverService.destroyAllServicePopups(); //destroy all existing popups created by this service... (note: it must be synchronous so we are guaranteed all destroyed after this)
            $target = $target || ($event && $event.target) || document.body;
            modelBean = modelBean || {
                openDelay: 1,
            };
            modelBean.hideEventParams = modelBean.hideEventParams || { event: "mouseleave" };
            popoverContentComponent = popoverContentComponent || PopoverDefaultComponent;
            return PopoverService.domService
                .getComponentRefAndElement(popoverContentComponent, { modelBean: modelBean })
                .then((popoverContentDomResp) => {
                    modelBean.popoverTemplate = popoverContentDomResp.instance.template;

                    return LangService.getLang().then(function (lang) {
                        //inject lang for convenience
                        //note we preserve the modelBean object reference, which is important as the caller expects to use attached functions after passing in a modelBean
                        S25Util.merge(modelBean, {
                            //set popup modelBean
                            data: {
                                lang: lang,
                            },
                            onHide: function (modelBean?: any) {
                                //on hide, cleanup / destroy everything
                                PopoverService.destroyPopup(modelBean.domResp);
                            },
                            hidePopup: function () {
                                //convenience function so callers can easily hide the popup without needing the $newScope
                                modelBean.hide && modelBean.hide();
                            },
                            hideAllServicePopups: function () {
                                //convenience function so callers can easily hide/destroy all popups
                                PopoverService.destroyAllServicePopups();
                            },
                            getPopupScope: function () {
                                //convenience function so callers can easily get the popup scope if they really need it
                                return modelBean.domResp;
                            },
                            onPopupCreated: ($popupScope: any) => {
                                console.log("onPopupCreated", $popupScope);
                                // let parent = $popupScope.modelBean.modelmodelBean.content.parents(".s25-popup");
                                // let offset = parent && parent.offset && parent.offset() || $popupScope.modelBean.modelmodelBean.content.offset();
                                // let w = $popupScope.modelBean.modelmodelBean.content.find("> *").width() || 0;
                                // let diff = window.innerWidth - offset.left - w;
                                //
                                // if(diff < 0 && parent && parent.length) {
                                //     parent.css({
                                //         "left": (offset.left + diff - 20) + "px"
                                //     });
                                // }
                                //
                                // //destroy popup when clicked outside (hiding on unfocus does not always work if the click does not focus anything else)
                                // //here we prevent popup destruction when clicked inside (by preventing bubble up to doc click, which destroys)
                                // $popupScope.modelBean.modelmodelBean.content.on("click.popupService", (e : any) => { //$popupScope.modelBean.modelmodelBean.content guaranteed to be defined when ran by s25-popup
                                //     e.stopPropagation();
                                // });
                                // $popupScope.modelBean.modelmodelBean.content.on("mouseover.popupService", (e : any) => { //$popupScope.modelBean.modelmodelBean.content guaranteed to be defined when ran by s25-popup
                                //     e.stopPropagation();
                                // });
                            },
                        });

                        if (PopoverService.popups.length === 0) {
                            PopoverService.destroyAllServicePopups(); //remove all again (in case interleaving execution snuck in)
                            //form main popover element
                            return PopoverService.domService
                                .appendComponentToTarget(PopoverComponent, $target, { modelBean: modelBean })
                                .then((domResp) => {
                                    modelBean.domResp = domResp; //set ref to domResp on modelBean for easy closing
                                    PopoverService.popups.push(domResp); //add domResp to popups array to track and use in close-all fn
                                    return PopoverService.zone.run(() => {
                                        modelBean.open && modelBean.open();

                                        // //destroy popup when clicked outside (hiding on unfocus does not always work if the click does not focus anything else)
                                        // //if clicked inside, see onPopupCreated, which stops propagation and thus prevents bubbling up to this doc handler
                                        // setTimeout(() => {
                                        //     //wait until popup is created to wire any hide events else, we could set off an event to hide that will arrive right after show is done!
                                        //     //meaning we cannot to stop propagate yet (shown in onPopupCreated)
                                        //     PopoverService.zone.run(() => {
                                        //         jSith.on(document.body, "click.popupService", () => {
                                        //             modelBean.close && modelBean.close();
                                        //         });
                                        //
                                        //         if(modelBean.hideEventParams.event.indexOf("mouseleave") > -1) {
                                        //             jSith.on(document.body, "mouseover.popupService", () => {
                                        //                 modelBean.close && modelBean.close();
                                        //             });
                                        //         }
                                        //     }); //note: 50ms delay seemed to work for this. Too short and it fires hide before popup is created and can stopPropagate, too long and it is jumpy
                                        // }, 50);

                                        PopoverService.isWorking = false; //open up to popups again
                                    });
                                });
                        }
                    });
                });
        }
    }

    public static destroyPopup(domResp: DomResp) {
        if (domResp) {
            //loop popups and remove the scope we are destroying from list and destroy it
            let len = PopoverService.popups.length;
            for (let i = len - 1; i >= 0; i--) {
                if (PopoverService.popups[i] === domResp) {
                    PopoverService.popups[i].destroy();
                    PopoverService.popups.splice(i, 1);
                    break;
                }
            }

            //in case somehow not in array, destroy it
            domResp.destroy();
        }
    }

    public static destroyAllServicePopups() {
        let len = PopoverService.popups.length;
        for (let i = len - 1; i >= 0; i--) {
            PopoverService.destroyPopup(PopoverService.popups[i]);
        }
        PopoverService.popups = [];
    }

    public static destroyAllPopups() {
        return PopoverService.destroyAllServicePopups();
    }
}
