//@author: devin
import { Component, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
    template: `
        <ng-template #defaultPopover>
            <div *ngIf="bean">
                {{ bean.message }}
            </div>
            <div *ngIf="!bean">TEST</div>
        </ng-template>
    `,
})
export class PopoverDefaultComponent {
    @Input() bean: any;
    @ViewChild(TemplateRef, { static: false }) template: TemplateRef<any>;
}
